@use "vars"

$color: #ddd
  
.consent
  position: fixed
  bottom: 0
  left: 0
  width: calc(100% - 4rem)
  background: vars.smoke(0.8)
  margin: 2rem
  padding: 2rem
  border-radius: 10px
  z-index: 99
  color: $color
  display: flex

  &.hidden
    display: none

  h4
    font-size: 1.6rem
  
  p
    font-size: 1.3rem
    margin: 0
    margin-top: 1.3rem
  
.consent-buttons
  align-self: center
  white-space: nowrap
  margin-left: auto

  button
    color: $color
    border: solid 1px darken($color, 30%)
    font-size: 1.2rem
    padding: 0 1.2rem
    line-height: 2
    border-radius: 10rem
    margin-left: 1rem
    @include vars.body-font()

    &.yes
      color: vars.$success
      border-color: vars.$success
    
    &.no
      border-color: vars.$error
      color: vars.$error
    
    &:hover
      color: inherit
      border-color: inherit