$light: #ccced8
$tan: rgb(193, 186, 176)
$green: rgb(42, 104, 133)
$purple: #412C56
$gray: rgb(66, 68, 78)
$error: #c52e42
$success: #2ec568

$medium: 640px
$large: 1024px

@mixin header-font()
  font-family: "futura-pt", sans-serif
  font-weight: 500

@mixin body-font()
  font-family: "museo-slab", sans-serif

@mixin small-only()
  @media screen and (max-width: $medium - 1)
    @content

@mixin medium()
  @media screen and (min-width: $medium)
    @content

@mixin large()
  @media screen and (min-width: $large)
    @content

@function mist($amount)
  @return rgba(255,255,255, $amount)

@function smoke($amount)
  @return rgba(0,0,0,$amount)

$black: smoke(0.7)
$white: mist(0.9)

@function gradient($from: #fff, $to: #000, $deg: 180)
  @return linear-gradient(#{$deg}deg, $from 0%, $to 100%)