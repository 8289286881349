.icon
  display: inline-block
  width: 1em
  height: auto
  stroke-width: 0
  stroke: currentColor
  fill: currentColor

.icon-plane
  width: 0.803em


.icon-angle-left
  width: 0.375em


.icon-angle-right
  width: 0.33928571428571425em


.icon-balance-scale
  width: 1.2142857142857142em


.icon-spaceinvaders
  width: 1.03125em
