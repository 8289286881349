@use "reset"
@use "vars"

@use "icons"
@use "consent"
@use "nav"
@use "parallax"
@use "top"
@use "about"
@use "separators"
@use "perks"
@use "contact"
@use "footer"

:root
  --contentPadding: 2.5rem
  --fontSize: 55%

@include vars.medium
  :root
    --contentPadding: 3rem
    --fontSize: 62.5%

@include vars.large
  :root
    --contentPadding: 5rem

*, *:before, *:after
  box-sizing: border-box

html
  font-size: var(--fontSize)
  height: 100%

body
  @include vars.body-font()
  background: vars.$white

h1,h2,h3
  @include vars.header-font()

h2
  font-size: 7rem
  padding-bottom: 1rem

  @include vars.large
    font-size: 8rem

h3
  font-size: 5rem
  text-align: center

strong
  font-weight: 600

em
  font-style: italic

button
  border: none
  line-height: 0
  padding: 0
  background: transparent
  cursor: pointer

  &:active, &:focus
    outline: none

section, footer
  max-width: 1140px
  margin: 0 auto
  color: vars.$black
  position: relative
  overflow: hidden

  @include vars.large
    width: calc(100vw - calc(2 * var(--contentPadding)))

section.fluid, footer
  max-width: none
  width: 100%

section.inverted, footer section
  color: vars.$white

article
  padding: calc(1.5 * var(--contentPadding)) var(--contentPadding)
  position: relative

p, .paragraph
  font-weight: 500
  line-height: 1.5
  font-size: 2.2rem
  -webkit-font-smoothing: antialiased
  margin: 4rem 0

.indented
  @include vars.medium
    padding-left: var(--contentPadding)

  @include vars.large
    max-width: calc(100% - 8rem)

.centered
  text-align: center

.with-shadow
  text-shadow: 0 1px 5px vars.smoke(0.3), 0 1px 2px vars.smoke(0.5)

.divider-header
    text-align: center
    padding: 0
    font-size: 2rem
    position: relative
    z-index: 1
    line-height: 6rem
    font-weight: 600
    background: vars.smoke(0.15)
    overflow: hidden
    text-transform: uppercase
    color: inherit
    transition: 1s