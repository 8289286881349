@use "vars"

@mixin transition($opacity, $x: 0)
  opacity: $opacity
  transform: translate3d($x, -50%, 0)

@mixin button($type: "normal")
  @if $type == "normal"
    color: vars.smoke(0.5)

    &:hover
      color: vars.smoke(0.8)

  @else
    color: vars.mist(0.5)

    &:hover
      color: vars.mist(0.8)

.perks
  transition: background 1s linear, height .6s cubic-bezier(0.45, 0, 0.55, 1)

  &.screen-0
    background: #754444
    color: vars.mist(0.7)
  &.screen-1
    background: #98c3ae
    color: vars.smoke(0.6)
  &.screen-2
    background: #2d495f
    color: vars.mist(0.7)
  &.screen-3
    background: #e4cfad
    color: vars.smoke(0.6)

  .controls
    position: absolute
    width: 100%
    height: 100%
    pointer-events: none
    z-index: 1
    top: 0

    section
      display: flex
      justify-content: space-between
      height: 100%
      
      @include vars.large
        padding: 0 2rem
    
    button
      font-size: 6rem
      pointer-events: all
      transition: .3s
      padding: 0 2rem
      @include button()
  
  &.screen-0 .controls button,
  &.screen-2 .controls button
    @include button("inverted")

  .screen
    min-width: 100vw
    position: absolute
    transition: all 1s
    font-size: 0.8rem
    left: 0
    top: 50%
    transform: translateY(-50%)
    line-height: 3
    
    &.c-in-forward-init
      @include transition(0, 100px)
    &.c-in-back-init
      @include transition(0, -100px)
    &.c-in-forward, &.c-in-back
      @include transition(1)
    
    &.c-out-forward
      @include transition(0, -100px)
    &.c-out-back
      @include transition(0, 100px)

    > section
      padding: 3rem 0
    
    article
      padding: 0 8em
      text-align: center
      display: flex

      @include vars.medium
        padding: 0 12em

      @include vars.large
        font-size: 1rem

      & > *
        margin-right: 2rem
      
      & > *:last-child
        margin-left: 2rem
        margin-right: 0
    
    .text
      width: 100%
    
    .icons
      position: relative
      display: none
      flex: 1

      @include vars.medium
        display: flex
    
    .icon
      width: 100%
      height: 100%
      max-height: 60%
      min-width: 12em
      align-self: center
    
    &.first
      .text
        @include vars.medium
          width: 75%

      p
        font-size: 3.3em
        
        &:first-child
          font-size: 4em

      .icons
        flex-direction: row-reverse
        justify-content: flex-end
        align-items: flex-end
        
      .icon
        transform: scale(0.9)
        
        &.second
          transform: scale(0.7) translateY(5em)
          margin-right: 1em
    
    &.second
      .text
        @include vars.medium
          width: 65%
      
      p
        font-size: 4em

      .icon
        position: relative

        &.first
          top: -4em
          left: 0
          transform: scale(0.9)

        &.second
          top: 5em
          left: 2em

    &.third
      .text
        @include vars.medium
          width: 70%

      p
        font-size: 4em

        span
          display: block
          margin-top: 2.2rem
          font-size: 2.6rem
          color: vars.mist(0.5)
  
      .icon
        width: 18rem
        height: 18rem
    
    &.fourth
      .text
        @include vars.medium
          width: 60%
      
      p
        font-size: 4em

      .icon.second
        transform: scale(0.85)
