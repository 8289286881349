@use "vars"

.about
  .hl
    font-weight: 600

  .hl-1
    color: #00c78f
  
  .hl-2
    color: #008fc7
  
  .hl-3
    color: #5600c7
  
  .hl-4
    color: #c76700
  
  .hl-5
    color: #f00048
    display: inline-block
    position: relative
    margin: 0 0.5rem

    &:before,
    &:after
      content: "\2665"
      position: absolute

    &:before
      font-size: 1.3rem
      top: -0.6rem
      left: -1.1rem
      transform: rotate(-20deg)
    
    &:after
      font-size: 1.2rem
      right: -1.1rem
      bottom: -0.7rem
      transform: rotate(10deg)