@use "vars"

$base: #80ad91

$dark: darken($base, 10)
$darker: desaturate(darken($base, 20), 25)
$background: transparentize($darker, 0.2)

@function transform($x, $y, $input)
  @return translate(calc(-50% + #{$x}), calc(-50% + #{$y})) $input

@keyframes squish
  2.5%
    transform: scale(1.2, 0.9)
  5%
    transform: scale(0.9, 1.2) translate(0, -4px)
  7.5%
    transform: scale(1)

@keyframes enlarge
  100%
    transform: scale(1.1)

.contact
  background-color: #c9ffe9

  .background
    background: vars.gradient(#ffe1a1, #c9ffe9)

  article
    position: relative
  
  h2
    mix-blend-mode: difference
    color: #505050
    width: 100%
  
  .form-container
    display: flex
    align-items: center
    flex-direction: column-reverse

    @include vars.medium
      flex-direction: row
  
  form
    flex: 1
    width: 100%

    @include vars.medium
      margin-top: 6rem

  p
    color: #777
    mix-blend-mode: difference
    flex: 1

    @include vars.medium
      padding-left: 5rem

  input, textarea, button
    border: none
    margin: 1rem 0
    background: vars.smoke(0.1)
    border-radius: 8px
    padding: 2rem
    font-size: 2.2rem
    transition: all .2s
    font-family: inherit
    color: rgba(0,0,0,.5)

  input, textarea
    box-shadow: inset 2px 2px 5px vars.smoke(0.1), 1px 1px 5px lighten(#e3f0c6, 5)

  label
    position: relative
    display: block

    span
      position: absolute
      white-space: nowrap
      top: 50%
      left: 2rem
      transform: translateY(-50%)
      font-size: 2rem
      color: transparentize($darker, 0.5)
      pointer-events: none
      transition: 0.4s ease-in-out

    textarea + span
      top: 4rem
    
    input, textarea
      &:focus, &:valid
        & + span
          color: transparentize($darker, 0.3)

    &:nth-child(1) input
      &:focus, &:valid
        & + span
          transform: translate(-40%, -324%) rotate(-10deg)

    &:nth-child(2) input
      &:focus, &:valid
        & + span
          transform: translate(-66px, -45%) rotate(-90deg)

    &:nth-child(3) textarea
      &:focus, &:valid
        & + span
          transform: translateY(-180%)

  input, textarea
    width: 100%

    &:focus, &:valid
      outline: none
      background: vars.smoke(0.2)
      box-shadow: inset 2px 2px 5px vars.smoke(0.2), 1px 1px 5px lighten(#e3f0c6, 5)

  textarea
    font-size: 1.8rem
    line-height: 1.5
    resize: none

    &:focus, &:valid
      margin-top: 3.5rem
  
  .form-bottom
    display: flex
    flex-direction: column-reverse
    align-items: flex-start

    @media screen and (min-width: 900px)
      flex-direction: row
      align-items: center

  button
    color: #b0cebb
    padding: 2.5rem 4rem
    transition: transform 2s
    user-select: none
    position: relative
    z-index: 1
    background: transparent
    transition: .2s
    margin-right: 2rem

    &::before, &::after
      content: ""
      height: 100%
      width: 100%
      position: absolute
      top: 0
      left: 0

    &::before
      background: transparent
      border-radius: 100%
      z-index: -1
      transition: .3s

    &::after
      background: #97b3a2
      border-radius: 8px
      z-index: -1

    &:focus
      outline: none
  
  #formStatus
    font-size: 1.4rem
    line-height: 1.5
    opacity: 0
    transition: opacity .4s, height .2s

    &.show
      opacity: 1

    &.error
      color: vars.$error
  
  form
    transition: opacity .4s

    &:valid button
      animation: squish 3.5s infinite
      color: #536359

      &:hover
        color: vars.$white
        animation: enlarge .3s forwards

        &::before
          box-shadow: 0 18px 14px -12px vars.smoke(0.3)
    
    &.disabled
      opacity: 0.5
      pointer-events: none

      button
        animation: none !important