@use "vars"

$color: #fff
$dimmed: transparentize($color, 0.4)
  
footer
  background: #2c2421
  font-size: 1.3rem
  line-height: 1.5
  @include vars.header-font()

  article
    padding-top: 4rem
    padding-bottom: 6rem
    display: flex
    justify-content: space-evenly
  
  a
    color: inherit
    text-decoration: none

    &:hover
      color: $dimmed

  .column
    color: $color

    h4
      font-size: 1.6rem
      text-transform: uppercase
      margin-bottom: 2rem
    
    .icon
      width: 4rem
      height: 4rem
      margin-right: 0.3rem
    
    &.sections a
      display: block
      font-weight: 400
      text-transform: uppercase
      margin-bottom: 1rem
    
    &.info
      h4
        width: 100%
      
      a:hover
        color: $color

      > div
        @include vars.body-font()
      
      .info-container
        display: flex
        flex-wrap: wrap
        margin-top: 1rem
      
      .icon
        width: 1.6rem
        height: 1.6rem
        vertical-align: middle
      
      .right
        flex-grow: 1
        color: $dimmed
        margin-left: 0.5rem
      
      strong
        display: block
        font-weight: 600
        color: $color
        margin-bottom: 0.3rem