[data-parallax-background]
  position: absolute
  top: 0
  left: 0
  object-fit: cover
  width: 100%
  height: 100%
  z-index: 0

[data-parallax-speed]
  will-change: transform