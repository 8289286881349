@use "vars"
  
$menuColor: #222

nav
  position: fixed
  left: 0
  top: 50%
  transform: translate3d(0, -50%, 0)
  z-index: 10
  padding-left: 2rem
  font-size: 2rem
  line-height: 1
  perspective: 2rem
  opacity: .5
  mix-blend-mode: multiply
  transition: opacity .3s ease-out
  color: $menuColor
  display: none

  @include vars.large
    display: block

  &:hover
    opacity: 1

.nav-link
  text-decoration: none
  transition: all .3s
  display: flex
  flex-direction: column
  align-items: center
  position: relative
  padding-right: 1.5rem
  color: $menuColor

  &::before
    content: ""
    height: 2rem
    width: 2rem
    background-color: transparent
    transition: all .5s
    border-radius: 100%
    border: 0.4rem solid $menuColor
    margin: 0.5rem 0
    will-change: background-color

  &::after
    content: ""
    height: 3rem
    width: 0
    border-left: 0.6rem dotted $menuColor

  &:last-child::after
    content: none

  span
    position: absolute
    left: 100%
    top: 0.3rem
    transition: all .5s cubic-bezier(.17,.35,.2,1)
    opacity: 0
    transform: translate3d(0px, 0px, -16px) rotateY(20deg)

  &:hover span
    opacity: 1
    transform: translate3d(0px, 0px, 0px) rotateY(0)

  &.active::before
    background-color: $menuColor