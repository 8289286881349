@use "vars"
  
.top
  width: 100%
  height: 100vh
  display: flex
  align-items: center
  justify-content: center
  background: #c8d6df

  & *::selection
    background: transparent

  h1
    color: vars.$tan
    line-height: 1
    position: relative
    cursor: default
    text-align: center

    img
      height: 100vh
      max-width: 50vw
      max-height: 50vh
      margin-bottom: 5rem

  span
    background: vars.mist(0.8)
    padding: 1rem
    display: block
    white-space: nowrap
    font-size: 4rem
    
    @include vars.medium
      position: absolute
      bottom: -4rem
      transform: translateX(-50%)
      left: 100%