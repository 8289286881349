@use "vars"

$background-1: #1F313F

section.separator-1
  background: $background-1
  z-index: -2

  @include vars.large
    padding-bottom: 28vh

  article
    display: flex
    flex-direction: column
    align-items: flex-start
    max-width: 100%
    align-items: center

    @include vars.large
      flex-direction: row
      padding-top: 4rem
      align-items: flex-start
    
    .paragraph
      font-size: 1.6rem
      text-align: center
      flex: 1
      margin-top: 0
      width: 100%

      span
        display: block
        text-align: center
        @include vars.header-font()
        font-size: 5rem
        font-weight: bold
      
      em
        display: block
        @include vars.header-font()
        font-style: normal
        font-size: 2.2rem
        font-weight: bold
        margin: 1.4rem 0 2rem

      ul
        margin: 0 auto
        width: 100%

        @include vars.medium
          width: 500px
        
        @include vars.large
          margin-left: 3rem
          width: auto
      
      li
        margin: 0.5rem 0 0.5rem 1rem
        text-align: left

        &::before
          content: "\2726"
          margin-right: 1rem

  aside
    margin: 0 4rem
    margin-bottom: 4rem
    @include vars.header-font()
    font-size: 8rem
    letter-spacing: -0.5rem
    padding: 0.8rem
    align-self: center
    border-radius: 100%
    border: solid 7px white
    background: rgba(255,255,255,.2)
    aspect-ratio: 1/1
    display: flex
    align-items: center
    order: -1

    @include vars.large
      order: initial
      margin-bottom: 0

    span
      font-size: 5rem
      font-weight: bold
      position: relative

      &:first-child
        left: 1.2rem
        top: -1.8rem
      
      &:last-child
        right: 1.8rem
        top: 2.5rem

  figure
    flex: 1
    margin: 3rem
    max-width: 200px

    @include vars.large
      margin: 0 3rem

  svg
    width: 100%

    path
      fill: vars.$white
  
  .footer
    position: absolute
    max-width: none
    width: 100%
    z-index: -1
    bottom: 7rem
    overflow: hidden
    display: none

    @include vars.large
      display: block
      bottom: 8rem

    h3
      line-height: 2
      text-transform: uppercase
      font-weight: 600
      white-space: nowrap
      position: relative
      display: inline-block
      left: 50%
      z-index: 10
      transform: translateX(-50%)